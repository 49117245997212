export const FirebaseConfig = {
	"projectId": "kurye-bfede",
	"appId": "1:751981008217:web:19e6b12cd516cdfdb12848",
	"databaseURL": "https://kurye-bfede-default-rtdb.europe-west1.firebasedatabase.app",
	"storageBucket": "kurye-bfede.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyCq46fzrQWMxhtovhv_v-gvIJw-LGY2yWA",
	"authDomain": "kurye-bfede.firebaseapp.com",
	"messagingSenderId": "751981008217",
	"measurementId": "G-VS6LTBHV5L"
};